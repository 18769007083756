import React, { Fragment, useState } from "react";
import type {
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Autocomplete,
  Box,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AssetContentFieldStatus, type AssetValue } from "@src/types";

export interface IFormListInput<T extends FieldValues> {
  fieldName: Path<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  mandatory?: boolean;
  options: string[];
  disabled?: boolean;
  withStatus?: boolean;
}

const FormListInput = <T extends FieldValues>({
  fieldName,
  watch,
  setValue,
  mandatory = false,
  options = [],
  disabled,
  withStatus,
}: IFormListInput<T>) => {
  const watchedField = watch(fieldName) as AssetValue[];
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editValue, setEditValue] = useState<string>("");

  const handleAddChip = (value: string) => {
    setValue(fieldName, [
      ...watchedField,
      {
        value: value,
        status: withStatus
          ? AssetContentFieldStatus.CREATED
          : AssetContentFieldStatus.NULL,
      },
    ] as PathValue<T, Path<T>>);
  };

  const handleDeleteChip = (chipIndex: number) => {
    const newWatchedField = watchedField;
    if (withStatus) {
      newWatchedField.splice(chipIndex, 1, {
        value: watchedField[chipIndex].value,
        status:
          watchedField[chipIndex].status === AssetContentFieldStatus.DELETED
            ? AssetContentFieldStatus.NULL
            : AssetContentFieldStatus.DELETED,
      });
    } else {
      newWatchedField.splice(chipIndex, 1);
    }
    setValue(fieldName, newWatchedField as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });
  };

  const handleSaveEditChip = (chipIndex: number) => {
    const newWatchedField = watchedField;
    newWatchedField.splice(chipIndex, 1, {
      value: editValue,
      status: withStatus
        ? AssetContentFieldStatus.MODIFIED
        : AssetContentFieldStatus.NULL,
    });
    setValue(fieldName, newWatchedField as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });
    setEditingIndex(null);
  };

  const handleEditChip = (chipIndex: number) => {
    setEditingIndex(chipIndex);
    setEditValue(watchedField[chipIndex].value);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption">{`${fieldName.replaceAll("_", " ")}${mandatory ? " *" : ""}`}</Typography>
      <Stack direction="row" gap={1} mb={1}>
        {watchedField?.map((chip, index) => (
          <Fragment key={index}>
            {editingIndex === index ? (
              <TextField
                key={index}
                size="small"
                value={editValue}
                disabled={disabled}
                onChange={(e) => setEditValue(e.target.value)}
                onBlur={() => {
                  handleSaveEditChip(index);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSaveEditChip(index);
                }}
                autoFocus
              />
            ) : (
              <Chip
                key={index}
                label={chip.value}
                clickable
                deleteIcon={
                  chip.status === AssetContentFieldStatus.DELETED ? (
                    <UndoIcon />
                  ) : (
                    <DeleteIcon />
                  )
                }
                disabled={disabled}
                onDelete={() => handleDeleteChip(index)}
                onClick={() => handleEditChip(index)}
              />
            )}
          </Fragment>
        ))}
      </Stack>
      <Autocomplete
        freeSolo
        multiple
        disabled={disabled}
        options={options}
        filterOptions={(options) =>
          options.filter(
            (option) => !watchedField.map((val) => val.value).includes(option),
          )
        }
        renderOption={(props, option, { inputValue }) => {
          const regex = new RegExp(inputValue, "i");
          const isPossibleValue = regex.test(option);
          return isPossibleValue ? <li {...props}>{option}</li> : <Fragment />;
        }}
        value={watchedField?.map((val) => val.value)}
        renderTags={() => null}
        onChange={(event, newValue) => {
          handleAddChip(newValue[newValue.length - 1]);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`add ${fieldName.replaceAll("_", " ")}`}
          />
        )}
      />
    </Box>
  );
};

export default FormListInput;
