import { createTheme } from "@mui/material/styles";

export const FONT_FAMILY = "Futura,sans-serif";

export const colors = {
  primary: "#0a0a0a",
  secondary: "#fefefe",
  white: "#FFFFFF",
  grayLight: "#EEEEEE",
  grayExtraLight: "#FAFAFA",
  gray: "#bdbdbd",
  grayTransparent: "rgba(105,97,78,0.27)",
  black: "#000000",
  success: "rgba(46,125,50,0.75)",
  warning: "rgba(237,108,2,0.75)",
  error: "rgb(201,45,59)",
  background: "#f9f9f9",
};

const theme = createTheme({
  shape: {
    borderRadius: 0,
  },
  palette: {
    primary: {
      main: colors.primary,
      dark: colors.primary,
      light: colors.white,
      contrastText: colors.grayExtraLight,
    },
    secondary: {
      main: colors.secondary,
      dark: colors.black,
      light: colors.gray,
      contrastText: colors.grayLight,
    },
    background: {
      default: colors.background,
    },
  },
  typography: {
    fontFamily: FONT_FAMILY,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: colors.primary,
          color: colors.secondary,
          ":hover": {
            backgroundColor: colors.grayTransparent,
            color: colors.primary,
          },
        },
        containedSecondary: {
          backgroundColor: colors.secondary,
          color: colors.primary,
          ":hover": {
            backgroundColor: colors.grayTransparent,
            color: colors.primary,
          },
        },
        contained: {
          backgroundColor: colors.secondary,
          color: colors.primary,
          ":hover": {
            backgroundColor: colors.grayTransparent,
            color: colors.secondary,
          },
        },
        outlined: {
          color: colors.primary,
          borderColor: colors.gray,
          "&.MuiButton-outlinedError": {
            color: colors.error,
            borderColor: colors.error,
          },
          "&.MuiButton-outlinedSuccess": {
            color: colors.success,
            borderColor: colors.success,
          },
        },
        text: {
          backgroundColor: colors.secondary,
          color: colors.primary,
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
          ":hover": {
            backgroundColor: colors.primary,
            color: colors.secondary,
          },
          "&.Mui-disabled": {
            color: colors.gray,
          },
        },
        root: {
          borderRadius: 3,
          ":hover": {
            borderRadius: 3,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled .MuiSvgIcon-root": {
            color: colors.grayTransparent,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          borderRadius: "6px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "14px",
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          alignItems: "center",
          justifyContent: "space-between",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-disabled .MuiSvgIcon-root": {
            color: colors.gray,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
});

export default theme;
