import React, { type FC } from "react";

import ButtonGrouped from "@components/ButtonGrouped/ButtonGrouped";
import GenericProductModal from "@components/Modals/GenericProductModal";
import ModalLayout from "@components/Modals/ModalLayout";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import type { MODAL_ACTION_TYPES } from "@src/types";

interface Props {
  id: number;
  actions: MODAL_ACTION_TYPES[];
  isAsset?: boolean;
}

const ProductPageHeader: FC<Props> = ({ id, actions, isAsset }) => {
  const { isPopupOpen, actionType, handleOpenPopup, handleClosePopup } =
    usePopupHandler();

  return (
    <>
      <ButtonGrouped
        options={actions.map((action) => ({
          label: action,
          onClick: () => handleOpenPopup(action),
        }))}
      />

      <ModalLayout
        open={isPopupOpen}
        onClose={handleClosePopup}
        title={actionType}
      >
        <GenericProductModal
          id={id}
          actionType={actionType}
          onClose={handleClosePopup}
          isAsset={isAsset}
        />
      </ModalLayout>
    </>
  );
};

export default ProductPageHeader;
