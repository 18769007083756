import React, { type FC, useEffect } from "react";

import { Button } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { useCloseSeasonMutation } from "@store/api/season";

interface Props {
  onClose: () => void;
  cleanName: string;
  isClosed?: boolean;
}

const CloseModal: FC<Props> = ({ onClose, cleanName, isClosed = false }) => {
  const [
    closeSeason,
    { isSuccess: isSuccessSeason, isLoading: isLoadingSeason },
  ] = useCloseSeasonMutation();

  useEffect(() => {
    if (isSuccessSeason) {
      onClose();
    }
  }, [isSuccessSeason]);

  const handleClick = () => {
    closeSeason({ isClosed, cleanName });
  };

  return (
    <Grid container my={2}>
      <Typography>{`Are you sure you want to ${isClosed ? "close" : "re-open"} the seasonal brief ?`}</Typography>
      <Grid
        item
        display="flex"
        justifyContent="space-between"
        width="100%"
        mt={4}
      >
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>

        <Button
          disabled={isSuccessSeason || isLoadingSeason}
          type="submit"
          onClick={handleClick}
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};

export default CloseModal;
