import styled from "styled-components";

export const FileUploaderInput = styled.input`
  display: none;
`;

export const FileRowList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  gap: 0;
  margin: 0;

  > * {
    position: relative;
  }
`;
