export enum Format {
  Large = "large",
  Thin = "thin",
  Icon = "icon",
  Classic = "classic",
  CarouselIcon = "carouselIcon",
  Info = "info",
}

export enum ComponentType {
  Button = "button",
  CancelButton = "CancelButton",
  Title = "Title",
  SubTitle = "SubTitle",
  SubTitleModified = "SubTitleModified",
  FormInput = "FormInput",
  SubSubTitle = "SubSubTitle",
  BriefAsset = "BriefAsset",
  Deadline = "Deadline",
  Classic = "classic",
  Validated = "validated",
  Waiting = "waiting",
  Canceled = "canceled",
  Comment = "comment",
  Error = "error",
  Text = "text",
  DefaultModified = "DefaultModified",
  File = "file",
  Arrow = "Arrow",
  Blocked = "Blocked",
  Open = "Open",
  InfoListText = "InfoListText",
  InfoListTitle = "InfoListTitle",
  InfoButtonText = "InfoButtonText",
  ColumnSpaceBetween = "ColumnSpaceBetween",
  ColumnEnd = "ColumnEnd",
  Boolean = "Boolean",
  TextCut = "TextCut",
}

export enum CommonAssetType {
  KEY_V_CAMPAIGN = "KEY V CAMPAIGN",
  DIGITAL_FULL_FACE_1 = "DIGITAL FULL FACE - Model shot KV applying the product",
  DIGITAL_FULL_FACE_2 = "DIGITAL FULL FACE - Model shot KV without product in hand and different from KV campaign",
  DIGITAL_FULL_FACE_3 = "DIGITAL FULL FACE - Model shot KV with product in hand",
  DIGITAL_FULL_FACE_STEPS = "DIGITAL FULL FACE Steps",
  B_A_APPLICATION = "BEFORE/AFTER Application",
  B_A_DIGITAL_FULL_FACE = "BEFORE/AFTER Digital Full Face",
  B_A_DIGITAL_CLOSE_UP = "BEFORE/AFTER Close up",
  B_A_DIGITAL_LABORATORY = "BEFORE/AFTER Laboratory",
  CLOSE_UP_EYES = "CLOSE UP Eyes",
  CLOSE_UP_LIPS = "CLOSE UP Lips",
  CLOSE_UP_BODY = "CLOSE UP Body",
  CLOSE_UP_HANDS = "CLOSE UP Hands",
  CLOSE_UP_STEPS = "CLOSE UP Steps",
  SWATCH = "SWATCH",
  TUTORIAL_VIDEO = "TUTORIAL VIDEO",
  UNBOXING_VIDEO = "UNBOXING VIDEO",
  ADVERTISING_MOVIE = "ADVERTISING MOVIE",
  ROUTINE = "ROUTINE",
  BATTLE = "BATTLE",
  STILL_LIFE_HERO_PACK = "STILL LIFE Hero Pack",
  STILL_LIFE_FAMILY = "STILL LIFE Family",
  STILL_LIFE_INGREDIENT = "STILL LIFE Ingredient",
  STILL_LIFE__CROSS_SELL = "TILL LIFE Cross Sell",
  SMEAR = "SMEAR",
  PACK_TEXTURE = "PACK + TEXTURE",
  PRODUCT_VIDEO = "PRODUCT VIDEO",
  UGV = "UGV",
  STILL_LIFE_All_Calendars = "STILL LIFE All Calendars",
  STILL_LIFE_Calendars_Premium_Classic = "STILL LIFE Calendars Premium + Classic",
  STILL_LIFE_Make_up = "STILL LIFE Make up",
  STILL_LIFE_Skincare = "STILL LIFE Skincare",
  STILL_LIFE_Bath = "STILL LIFE Bath",
  STILL_LIFE_Must_haves = "STILL LIFE Must-haves",
  STILL_LIFE_Small_budget = "STILL LIFE Small budget",
  STILL_LIFE_Pillars = "STILL LIFE Pillars",
  STILL_LIFE_Buy_3_get_1_free = "STILL LIFE Buy 3 get 1 free",
  STILL_LIFE_Accessories = "STILL LIFE Accessories",
}
