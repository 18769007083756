import type { MouseEventHandler } from "react";
import React from "react";

import { StyledIcon } from "@components/Icon/Icon.styled";
import * as Icons from "@mui/icons-material";
import type { ComponentType } from "@utils/enum.utils";

interface IIconButtonProps {
  icon: keyof typeof Icons;
  onClick?: MouseEventHandler<HTMLDivElement>;
  type?: ComponentType;
  addBorder?: boolean;
  position?: string;
  iconWidth?: string;
  iconHeight?: string;
  iconColor?: string;
  clickable?: boolean;
}

function Icon({
  icon,
  addBorder,
  position,
  type,
  iconWidth,
  iconHeight,
  iconColor,
  onClick,
  clickable,
}: IIconButtonProps) {
  let svgProps = { style: {} };

  if (iconWidth && iconHeight) {
    svgProps = {
      style: { width: iconWidth, height: iconHeight, color: iconColor },
    };
  }

  if (clickable) {
    svgProps = {
      style: {
        ...svgProps.style,
        cursor: "pointer",
      },
    };
  }

  return (
    <StyledIcon
      onClick={onClick}
      type={type}
      addBorder={addBorder}
      position={position}
    >
      {React.createElement(Icons[icon], svgProps)}
    </StyledIcon>
  );
}

export default Icon;
