import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { type FieldPath, useForm } from "react-hook-form";

import Carousel from "@components/Carousel/Carousel";
import Dropzone from "@components/Dropzone";
import IconEqual from "@components/Icon/IconEqual";
import IconMultiplication from "@components/Icon/IconMultiplication";
import GenericProductModal from "@components/Modals/GenericProductModal";
import ModalLayout from "@components/Modals/ModalLayout";
import ProductActions from "@components/ProductActions";
import AssetFormField from "@components/inputs/AssetFormField";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import {
  ACCEPT_FILES_TYPES,
  AssetContentFieldStatus,
  type AssetFileValueRequest,
  Authority,
  MODAL_ACTION_TYPES,
} from "@src/types";
import {
  useDeleteBenchVisualAssetMutation,
  usePutAssetMutation,
} from "@store/api/asset";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectProductId } from "@store/product/product.selector";
import {
  selectCanModifySeasonData,
  selectedSeason,
} from "@store/season/season.selector";
import { getDirtyValues, getOnlyValueFromAsset } from "@utils/fonctions.utils";

import dayjs from "dayjs";
import type { Asset, AssetForm } from "types/api/asset";

interface IProductsElementListProps {
  asset: Asset;
}

const AssetsElementList: FC<IProductsElementListProps> = ({ asset }) => {
  const season = useAppSelector(selectedSeason);
  const productId = useAppSelector(selectProductId);

  const canUpdateAsset = useAppSelector((state) =>
    selectCanModifySeasonData(state, Authority.UPDATE_ASSET),
  );
  const canUpdateDoneByImageTeam = useAppSelector((state) =>
    selectUserHasAuthority(state, Authority.UPDATE_ASSET_IMAGE_TEAM),
  );

  const [isOpenCollaspe, setIsOpenCollapse] = useState<boolean>(false);
  const [useStatus, setUseStatus] = useState<boolean>(false);

  const [deleteBenchVisuals] = useDeleteBenchVisualAssetMutation();
  const [putAsset] = usePutAssetMutation();

  const { isPopupOpen, actionType, handleOpenPopup, handleClosePopup } =
    usePopupHandler();

  const assetForm = getOnlyValueFromAsset(asset);
  const {
    register,
    control,
    watch,
    setValue,
    formState: { isDirty, dirtyFields },
  } = useForm<AssetForm>({
    defaultValues: {
      ...assetForm,
    },
  });

  const formValues = watch();

  const onSubmit = () => {
    if (productId) {
      const fieldsToUpdate = getDirtyValues(
        dirtyFields,
        formValues,
        assetForm,
        useStatus,
      );

      const filteredBenchVisuals = formValues.bench_visuals.value
        .filter(
          (item): item is AssetFileValueRequest => item.value instanceof File,
        )
        .map((item) => {
          return {
            value: item.value,
            status: item.status,
          };
        });

      if (
        Object.keys(fieldsToUpdate).length > 0 ||
        filteredBenchVisuals.length > 0
      ) {
        putAsset({
          assetSimpleField: fieldsToUpdate,
          bench_visuals: filteredBenchVisuals,
          id: asset.id,
        });
      }
    }
  };

  useEffect(() => {
    if (season) {
      setUseStatus(dayjs().isAfter(season.deadlineNoDef));
    }
  }, [season]);

  useEffect(
    () => {
      if (isDirty && Object.keys(dirtyFields).length > 0) {
        onSubmit();
      }
    },
    Object.values(formValues).map((val) => val.value),
  );

  const handleUpdateArrayValue = (
    fieldName: FieldPath<AssetForm>,
    value: AssetFileValueRequest,
  ) => {
    if (Array.isArray(watch(fieldName))) {
      setValue(fieldName, [...(watch(fieldName) as Array<any>), value], {
        shouldDirty: true,
      });
    } else {
      setValue(fieldName, [value], { shouldDirty: true });
    }
  };

  const handleDeleteBenchVisuals = (index: number) => {
    if (bench_visuals && productId) {
      deleteBenchVisuals({
        file: bench_visuals.value[index]?.value.name,
        id: asset.id,
      });
    }
  };

  const { bench_visuals } = watch();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  const _renderTab = () => {
    if (tabValue === 0) {
      return (
        <Grid container gap={2} display="flex" flexDirection="column">
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.ppage}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.digital_animation}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
        </Grid>
      );
    } else if (tabValue === 1) {
      return (
        <Grid container gap={2} display="flex" flexDirection="column">
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.media_regional_context}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.media_traditional_tv_dooh_ooh}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.media_digital}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
        </Grid>
      );
    } else if (tabValue === 2) {
      return (
        <Grid container gap={2}>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.permanent_instore}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.temporary_instore}
              register={register}
              useStatus={useStatus}
            />
          </Grid>

          <Grid
            container
            gap={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Grid item>
              <AssetFormField
                control={control}
                watch={watch}
                setValue={setValue}
                field={asset.content.nb_of_carnations}
                register={register}
                useStatus={useStatus}
              />
            </Grid>
            <Grid item>
              <IconMultiplication fontSize="small" />
            </Grid>
            <Grid item>
              <AssetFormField
                control={control}
                watch={watch}
                setValue={setValue}
                field={asset.content.nb_of_shades_to_shoot}
                register={register}
                useStatus={useStatus}
              />
            </Grid>
            <Grid item>
              <IconEqual fontSize="large" />
            </Grid>
            <Grid item>
              <AssetFormField
                control={control}
                watch={watch}
                setValue={setValue}
                field={asset.content.total_nb_of_assets}
                register={register}
                useStatus={useStatus}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Paper sx={{ p: 2, m: 2 }}>
      <form>
        <Grid container display="flex" justifyContent="space-between">
          <Grid container item gap={2} width="auto">
            <Grid item>
              <IconButton
                onClick={() => setIsOpenCollapse((prevState) => !prevState)}
              >
                {isOpenCollaspe ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">{asset.type}</Typography>
            </Grid>
            {asset.isCancel && (
              <Tooltip title={asset.whyIsCancel}>
                <Chip icon={<CancelOutlinedIcon />} label="Cancelled" />
              </Tooltip>
            )}
            {asset.isWaitingForApproval && (
              <Tooltip title={asset.whyIsWaitingForApproval}>
                <Chip
                  icon={<AutorenewOutlinedIcon />}
                  label="Waiting for approval"
                />
              </Tooltip>
            )}
          </Grid>
          {canUpdateAsset && (
            <Grid item>
              <ProductActions
                id={asset.id}
                isAsset
                actions={[
                  asset.isWaitingForApproval
                    ? MODAL_ACTION_TYPES.UNSET_WAITING_FOR_APPROVAL
                    : MODAL_ACTION_TYPES.SET_WAITING_FOR_APPROVAL,
                  asset.isCancel
                    ? MODAL_ACTION_TYPES.UNDO_CANCEL
                    : MODAL_ACTION_TYPES.CANCEL,
                  MODAL_ACTION_TYPES.DELETE_ASSET,
                ]}
              />
            </Grid>
          )}
        </Grid>
        <Box
          my={2}
          ml={2}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" gap={2}>
            {[
              asset.content.digital,
              asset.content.infrographic,
              asset.content.print,
              asset.content.media,
            ].map((field) => (
              <AssetFormField
                key={field.name}
                control={control}
                watch={watch}
                setValue={setValue}
                field={field}
                register={register}
                useStatus={useStatus}
              />
            ))}
          </Stack>
          <Stack direction="row" gap={2}>
            {asset.content.new_tag.possibleValues?.map((tag) => (
              <Chip
                key={tag}
                size="small"
                variant={
                  formValues.new_tag.value
                    .map(({ value }) => value)
                    .includes(tag)
                    ? "filled"
                    : "outlined"
                }
                onClick={() => {
                  if (
                    formValues.new_tag.value
                      .map(({ value }) => value)
                      .includes(tag)
                  ) {
                    setValue(
                      "new_tag.value",
                      formValues.new_tag.value.filter(
                        (value) => value.value !== tag,
                      ),
                      { shouldDirty: true },
                    );
                  } else {
                    setValue(
                      "new_tag.value",
                      [
                        ...formValues.new_tag.value,
                        { value: tag, status: AssetContentFieldStatus.NULL },
                      ],
                      { shouldDirty: true },
                    );
                  }
                }}
                label={tag}
              />
            ))}
          </Stack>
        </Box>
        <Box mx={2}>
          <Collapse in={isOpenCollaspe} timeout="auto" unmountOnExit>
            <Grid container gap={2}>
              {dayjs().isAfter(season?.deadlineDef) && !asset.isDelivered && (
                <Grid item>
                  <Typography variant="caption">
                    Why is this asset not delivered ?
                  </Typography>
                  <Typography> {asset.whyNotDelivered}</Typography>
                </Grid>
              )}
            </Grid>
            <Grid container gap={2}>
              {dayjs().isAfter(season?.deadlineDef) && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={asset.isDelivered}
                        disabled={!canUpdateDoneByImageTeam}
                        onClick={() => {
                          handleOpenPopup(
                            asset.isDelivered
                              ? MODAL_ACTION_TYPES.UNDELIVER_ASSET
                              : MODAL_ACTION_TYPES.DELIVER_ASSET,
                          );
                        }}
                      />
                    }
                    label="Done by Image team"
                  />
                  <ModalLayout
                    open={isPopupOpen}
                    onClose={handleClosePopup}
                    title={actionType}
                  >
                    <GenericProductModal
                      id={asset.id}
                      actionType={actionType}
                      onClose={handleClosePopup}
                      isAsset
                    />
                  </ModalLayout>
                </Grid>
              )}
            </Grid>
            <Grid container gap={2}>
              <Grid item>
                <Dropzone
                  title="Bench Visuals *"
                  handleUploadFile={(file) =>
                    handleUpdateArrayValue("bench_visuals.value", {
                      value: file[0],
                      status: AssetContentFieldStatus.NULL,
                    })
                  }
                  accept={ACCEPT_FILES_TYPES.BENCH_VISUALS}
                />
                <Carousel
                  title="Bench Visuals *"
                  images={bench_visuals.value}
                  maxImagesShown={4}
                  handleClickDelete={(index) => {
                    handleDeleteBenchVisuals(index);
                    setValue(
                      "bench_visuals.value",
                      bench_visuals.value.splice(index, 1),
                      {
                        shouldDirty: true,
                      },
                    );
                  }}
                />
              </Grid>
              <Grid item>
                <AssetFormField
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  field={asset.content.skus}
                  register={register}
                  useStatus={useStatus}
                />
              </Grid>
              <Grid item>
                <AssetFormField
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  field={asset.content.shades}
                  register={register}
                  useStatus={useStatus}
                />
              </Grid>
              <Grid item>
                <AssetFormField
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  field={asset.content.comment_from_marketing_product}
                  register={register}
                  useStatus={useStatus}
                />
              </Grid>
              <Grid item>
                <AssetFormField
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  field={asset.content.geographic_scope}
                  register={register}
                  useStatus={useStatus}
                />
              </Grid>
            </Grid>
            <Tabs value={tabValue} onChange={handleTabChange} sx={{ my: 2 }}>
              {["Digital", "Media", "Print"].map((tab) => (
                <Tab key={tab} label={tab} />
              ))}
            </Tabs>
            <Box my={2}>{_renderTab()}</Box>
          </Collapse>
        </Box>
      </form>
    </Paper>
  );
};

export default AssetsElementList;
