import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import type { WaitingForApprovalRequest } from "@src/types";
import { useWaitingForApprovalAssetMutation } from "@store/api/asset";

interface AddProductDialogProps {
  onClose: () => void;
  id: number;
  isWaitingForApproval?: boolean;
}

const WaitingForApprovalModal: React.FC<AddProductDialogProps> = ({
  onClose,
  id,
  isWaitingForApproval = false,
}) => {
  const [waitingForApprovalAsset, { isLoading, isSuccess }] =
    useWaitingForApprovalAssetMutation();
  const {
    register,
    watch,
    trigger,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<WaitingForApprovalRequest>({
    mode: "onBlur",
    defaultValues: {
      whyIsWaitingForApproval: "",
    },
  });

  const onSubmit = async () => {
    if (isWaitingForApproval) {
      await trigger();
    }
    if (isValid) {
      waitingForApprovalAsset({
        id,
        whyIsWaitingForApproval,
        isWaitingForApproval,
      });
    }
  };

  const whyIsWaitingForApproval = watch("whyIsWaitingForApproval");

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {isWaitingForApproval && (
        <Box my={4}>
          <TextField
            label="Motive of pending approval *"
            type="text"
            fullWidth
            error={!!errors.whyIsWaitingForApproval}
            {...register("whyIsWaitingForApproval", {
              required: "Reason is required",
            })}
          />
          {errors.whyIsWaitingForApproval && (
            <FormHelperText error>
              {errors.whyIsWaitingForApproval?.message}
            </FormHelperText>
          )}
        </Box>
      )}

      <Typography>
        {isWaitingForApproval
          ? "Do you want to change asset status to: waiting for approval ?"
          : "Do you want to change asset status to: approved ?"}
      </Typography>

      <Grid container my={2}>
        <Grid item display="flex" justifyContent="space-between" width="100%">
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>

          <Button disabled={isLoading} type="submit">
            Confirm
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default WaitingForApprovalModal;
