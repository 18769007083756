import React, { type FC } from "react";

import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  type ModalProps,
  Typography,
} from "@mui/material";

const ModalLayout: FC<ModalProps & { title: string }> = ({
  title,
  ...props
}) => {
  return (
    <Modal {...props}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          py: 2,
          px: 4,

          maxHeight: "80%",
          overflow: "scroll",
        }}
      >
        <Grid container rowGap={2}>
          <Grid container item display="flex" justifyContent="flex-end">
            <IconButton
              onClick={(e) =>
                props.onClose && props.onClose(e, "backdropClick")
              }
            >
              <CloseOutlined />
            </IconButton>
          </Grid>
          <Grid container item>
            <Grid item display="flex">
              <Typography variant="h4">{title}</Typography>
            </Grid>
          </Grid>
          {props.children}
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalLayout;
