import React, { type FC } from "react";

import { Paper } from "@mui/material";
import ProductPageHeaderData from "@pages/ProductPage/components/ProductPageHeader/ProductPageHeaderData";
import SeasonPageHeaderSkeleton from "@pages/SeasonPage/components/SeasonPageHeader/SeasonPageHeaderSkeleton";
import type { ProductGetResponse } from "@src/types";

interface Props {
  product?: ProductGetResponse;
  isLoading?: boolean;
}

const ProductPageHeader: FC<Props> = ({ product, isLoading }) => (
  <Paper sx={{ p: 2 }}>
    {isLoading ? (
      <SeasonPageHeaderSkeleton />
    ) : product ? (
      <ProductPageHeaderData product={product} />
    ) : null}
  </Paper>
);
export default ProductPageHeader;
