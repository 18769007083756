import React, { type FC, useEffect } from "react";

import { Button, Grid, Typography } from "@mui/material";
import { useDeleteAssetMutation } from "@store/api/asset";
import { useDeleteProductMutation } from "@store/api/product";

interface Props {
  onClose: () => void;
  id: number;
  isAsset?: boolean;
}

const DeleteModal: FC<Props> = ({ onClose, id, isAsset }) => {
  const [
    deleteAsset,
    { isLoading: isLoadingAsset, isSuccess: isSuccessAsset },
  ] = useDeleteAssetMutation();
  const [
    deleteProduct,
    { isLoading: isLoadingProduct, isSuccess: isSuccessProduct },
  ] = useDeleteProductMutation();

  useEffect(() => {
    if (isSuccessAsset || isSuccessProduct) {
      onClose();
    }
  }, [isSuccessAsset, isSuccessProduct]);

  const handleClickDelete = () => {
    if (isAsset) {
      deleteAsset({ id });
    } else {
      deleteProduct({ id });
    }
  };

  return (
    <Grid container my={2}>
      <Typography>Are you sure you want to delete this item ?</Typography>
      <Grid
        item
        display="flex"
        justifyContent="space-between"
        width="100%"
        mt={4}
      >
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>

        <Button
          disabled={isLoadingAsset || isLoadingProduct}
          type="submit"
          onClick={handleClickDelete}
        >
          Confirm deletion
        </Button>
      </Grid>
    </Grid>
  );
};

export default DeleteModal;
