import type { FC } from "react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AddProductModal from "@components/Modals/AddProductModal";
import ModalLayout from "@components/Modals/ModalLayout";
import { AddOutlined } from "@mui/icons-material";
import { Button, Grid, Skeleton, Stack } from "@mui/material";
import ProductListItem from "@pages/SeasonPage/components/ProductsList/components/ProductListItem";
import type { ProductGroupedByLevel } from "@src/types";
import { Authority } from "@src/types";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { ProductText } from "@utils/constants.utils";

interface Props {
  products?: ProductGroupedByLevel[];
  isLoading?: boolean;
}

const ProductsList: FC<Props> = ({ products, isLoading }) => {
  const { t } = useTranslation();

  const [isModalOpen, setOpenModal] = useState(false);

  const canAddProduct = useAppSelector((state) =>
    selectUserHasAuthority(state, Authority.CREATE_PRODUCT_BRIEF),
  );

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid container display="flex">
        <Grid item sx={{ ml: "auto", mr: 4, my: 4 }}>
          <Button
            startIcon={<AddOutlined />}
            onClick={handleOpenModal}
            disabled={!canAddProduct}
          >
            Add a product
          </Button>
        </Grid>
      </Grid>
      {isLoading && (
        <Stack direction="column" gap={4} mx={4}>
          {[...Array(2)].map((_, i) => (
            <Skeleton key={i} variant="rectangular" width="100%" height={240} />
          ))}
        </Stack>
      )}
      <Stack direction="column" gap={4} mx={4}>
        {products?.map((product) => (
          <ProductListItem product={product} key={product.level} />
        ))}
      </Stack>

      {canAddProduct && (
        <ModalLayout
          open={isModalOpen}
          onClose={handleCloseModal}
          title={t(ProductText.NewProduct)}
        >
          <AddProductModal />
        </ModalLayout>
      )}
    </>
  );
};

export default ProductsList;
