import React, { type FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

import ButtonGrouped from "@components/ButtonGrouped/ButtonGrouped";
import {
  DownloadOutlined,
  LockOpenOutlined,
  LockOutlined,
} from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import type {
  SeasonsGlobalInformations,
  SeasonsOrderByYears,
} from "@src/types";
import { useLazyExportPPTXQuery } from "@store/api/export";

import { AssetTypology } from "types/api/export";

interface Props {
  season: SeasonsOrderByYears;
}

const SeasonsRows: FC<Props> = ({ season }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const handleClickSeason = (element: SeasonsGlobalInformations) => {
    const redirectWithParams = generatePath("season/:cleanName", {
      cleanName: element.cleanName,
    });
    navigation(redirectWithParams);
  };

  const [exportPPTX] = useLazyExportPPTXQuery();

  return (
    <>
      {season.seasons.map((seasonGroup) => (
        <Stack
          direction="row"
          key={seasonGroup.seasonTypeId}
          display="flex"
          alignItems="center"
          gap={2}
          useFlexGap
          sx={{ flexWrap: "wrap" }}
        >
          <Typography variant="body2" width={100}>
            {t(seasonGroup.seasonTypeName)}
          </Typography>
          {seasonGroup.elements.map((element) => (
            <Fragment key={element.id}>
              <Card sx={{ maxWidth: 320, mt: 2, overflow: "visible" }}>
                <CardActionArea onClick={() => handleClickSeason(element)}>
                  <CardContent>
                    <Grid
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Grid item>
                        <Typography variant="h6">{element.name}</Typography>
                      </Grid>
                      <Grid item>
                        {element.isClosed ? (
                          <LockOutlined color="error" />
                        ) : (
                          <LockOpenOutlined color="success" />
                        )}
                      </Grid>
                      <Grid item>
                        <ButtonGrouped
                          onClick={(e) => e.stopPropagation()}
                          icon={<DownloadOutlined />}
                          options={Object.values(AssetTypology).map((item) => ({
                            label: item,
                            onClick: () =>
                              exportPPTX({
                                seasonCleanName: element.cleanName,
                                typology: item,
                              }),
                          }))}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Fragment>
          ))}
        </Stack>
      ))}
    </>
  );
};

export default SeasonsRows;
