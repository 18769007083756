import React, { type FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { FileUploaderInput } from "@components/FileUploader/FileUploader.styled";
import { StyledPreviewImage } from "@components/Image/Image.styled";
import { AutorenewOutlined } from "@mui/icons-material";
import { Button, Chip, Stack, Typography } from "@mui/material";
import type { ACCEPT_FILES_TYPES, FileComponentGetDetails } from "@src/types";
import { FilesText } from "@utils/constants.utils";

export interface FileUploaderProps {
  files?: (File | FileComponentGetDetails)[];
  limit: number;
  accept: ACCEPT_FILES_TYPES;
  disabled?: boolean;
  mandatory?: boolean;
  handleUploadFiles: (files: File[]) => void;
  handleDeleteFile?: (index: number) => void;
}

const FileUploader: FC<FileUploaderProps> = ({
  files = [],
  disabled,
  mandatory,
  handleUploadFiles,
  handleDeleteFile,
  limit,
  accept,
}) => {
  const { t } = useTranslation();
  const [isReplacing, setIsReplacing] = useState<number | undefined>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleDelete = (index: number) => {
    if (handleDeleteFile) {
      handleDeleteFile(index);
    }
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { files: filesList },
    } = event;
    if (isReplacing !== undefined) {
      handleDelete(isReplacing);
      setIsReplacing(undefined);
    }
    if (filesList) {
      const newFiles = Array.from(filesList);
      handleUploadFiles(newFiles);
    }
  };

  const shouldReplaceFile = files.length === 1 && mandatory;

  return (
    <>
      <FileUploaderInput
        ref={(e) => {
          fileInputRef.current = e;
        }}
        type="file"
        onChange={handleFileInputChange}
        multiple={limit > 1}
        accept={accept}
        disabled={disabled}
      />
      {files.length ? (
        <Stack direction="row" gap={1}>
          {files.map((file, index) => (
            <Chip
              key={index}
              size="small"
              label={
                <Typography>
                  {file instanceof File ? file.name : file.fileName}
                  {file instanceof File ? (
                    <StyledPreviewImage src={URL.createObjectURL(file)} />
                  ) : (
                    <StyledPreviewImage src={file.url} />
                  )}
                </Typography>
              }
              {...(!disabled &&
                !shouldReplaceFile && { onDelete: () => handleDelete(index) })}
              {...(!disabled &&
                shouldReplaceFile && {
                  clickable: true,
                  icon: <AutorenewOutlined />,
                  onClick: () => {
                    setIsReplacing(index);
                    fileInputRef.current?.click();
                  },
                })}
            />
          ))}
        </Stack>
      ) : (
        <Button
          onClick={handleClick}
          disabled={disabled || files.length >= limit}
        >
          {t(FilesText.UploadFile)}
        </Button>
      )}
    </>
  );
};

export default FileUploader;
