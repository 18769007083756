import React, { type FC } from "react";
import { useNavigate } from "react-router-dom";

import ProductActions from "@components/ProductActions";
import { CancelOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import type { ProductPreviewDetails } from "@src/types";
import { MODAL_ACTION_TYPES } from "@src/types";
import { ProductText } from "@utils/constants.utils";
import { convertTimeZoneInDate } from "@utils/fonctions.utils";

interface Props {
  productPreview: ProductPreviewDetails;
}

const ProductCard: FC<Props> = ({ productPreview }) => {
  const navigation = useNavigate();

  const handleClickViewDetails = () => {
    navigation(`/product/${productPreview.id}`);
  };

  return (
    <>
      <Paper
        key={productPreview.id}
        sx={{
          display: "flex",
          flexDirection: "row",
          maxHeight: 160,
        }}
      >
        <Box>
          <img
            src={productPreview.packshots[0]?.url}
            style={{ height: 160, maxWidth: 200, objectFit: "contain" }}
          />
        </Box>
        <Box m={2}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography gutterBottom variant="h6" component="div" mb={0}>
              {productPreview.name}
            </Typography>
            {productPreview.isCancel && (
              <CancelOutlined color="error" sx={{ ml: 2 }} />
            )}
          </Box>
          <Grid
            container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            my={1}
          >
            <Stack direction="row" spacing={1}>
              {[
                `${productPreview.numberOfSkus || 0} ${ProductText.SKUs}`,
                ...(productPreview.additionalPhotos
                  ? [
                      `${productPreview.additionalPhotos?.length} ${ProductText.Visuals}`,
                    ]
                  : []),
                `${productPreview.numberOfAssets || 0} ${ProductText.Assets}`,
              ].map((value) => (
                <Chip label={value} key={value} size="small" />
              ))}
            </Stack>
          </Grid>
          <Typography variant="caption">
            {ProductText.LastUpdate +
              convertTimeZoneInDate(productPreview?.lastUpdate ?? null)}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button onClick={handleClickViewDetails}>View details</Button>
            <ProductActions
              id={productPreview.id}
              actions={[
                MODAL_ACTION_TYPES.DUPLICATE_PRODUCT,
                MODAL_ACTION_TYPES.CANCEL,
                MODAL_ACTION_TYPES.DELETE_PRODUCT,
              ]}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ProductCard;
