import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";

import i18n from "@languages/i18n";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import App from "@src/App";
import "@src/index.css";
import theme from "@src/theme";
import store, { persistor } from "@store/store";

import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={5}>
                <App />
              </SnackbarProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </I18nextProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
);
