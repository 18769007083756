import type { FC } from "react";
import React, { useState } from "react";

import AddAssetModal from "@components/Modals/AddAssetModal";
import ModalLayout from "@components/Modals/ModalLayout";
import { AddOutlined } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import AssetsElementList from "@pages/ProductPage/components/AssetsElementList/AssetsElementList";
import type { ProductGetResponse } from "@src/types";
import { Authority } from "@src/types";
import { useAppSelector } from "@store/hooks";
import { selectCanModifySeasonData } from "@store/season/season.selector";

interface Props {
  product: ProductGetResponse;
}

const AssetsList: FC<Props> = ({ product }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const canCreateAsset = useAppSelector((state) =>
    selectCanModifySeasonData(state, Authority.CREATE_ASSET),
  );

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <Box m={2}>
      <Grid
        container
        item
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Grid item display="flex" alignItems="center" gap={2}>
          <Grid item>
            <Typography variant="h4">{`${product.nbAssets} assets`}</Typography>
          </Grid>
          {product.nbModifiedAssets > 0 && (
            <Grid item>
              <Typography variant="caption">
                - {product.nbModifiedAssets} modified assets
              </Typography>
            </Grid>
          )}
        </Grid>

        {canCreateAsset && (
          <Grid item>
            <Button
              onClick={handleOpenPopup}
              startIcon={<AddOutlined />}
              fullWidth
            >
              Add an asset
            </Button>
            <ModalLayout
              open={isPopupOpen}
              onClose={handleClosePopup}
              title="Add an asset"
            >
              <AddAssetModal productId={product.id} />
            </ModalLayout>
          </Grid>
        )}
      </Grid>

      {product.assets.map((asset) => (
        <AssetsElementList asset={asset} key={asset.id} />
      ))}
    </Box>
  );
};

export default AssetsList;
