import { styled as MUIstyled } from "@mui/material";
import type { AssetContentFieldStatus } from "@src/types";

import styled from "styled-components";

interface IStyledImageContainer {
  isImgCarousel?: boolean;
  status?: AssetContentFieldStatus;
}

export const StyledImageContainer = styled.div<IStyledImageContainer>`
  max-width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledImageBubble = MUIstyled("div", {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected?: boolean }>(({ selected = false, theme }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "32px",
  ...(selected && {
    border: `3px solid ${theme.palette.primary.main}`,
  }),
  "&:hover": {
    opacity: 0.5,
    cursor: "pointer",
  },
}));

export const StyledImagePlaceholder = styled.div`
  width: 100%;
  height: auto;
  background-color: var(--light-grey-color);
`;

export const StyledImage = styled.img`
  height: 100%;
  object-fit: cover;
`;

export const StyledPreviewImage = styled.img`
  border-radius: 10px;
  border: 2px solid var(--text-color);
  height: 200px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%);
  display: none;
`;
