import React, { type FC, useState } from "react";
import { useTranslation } from "react-i18next";

import AddSeasonModal from "@components/Modals/AddSeasonModal";
import ModalLayout from "@components/Modals/ModalLayout";
import { LanguageKey } from "@languages/enum";
import { AddOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import SeasonsRows from "@pages/HomePage/components/SeasonsRows";
import LogoSephoraCollection from "@resources/images/LogoSephoraCollection";
import { Authority } from "@src/types";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectSeasons } from "@store/season/season.selector";
import { NewSeason } from "@utils/constants.utils";

const HomePage: FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  const seasonList = useAppSelector(selectSeasons);

  const canCreateSeasonalBrief =
    useAppSelector((state) =>
      selectUserHasAuthority(state, Authority.CREATE_SEASONAL_BRIEF),
    ) || true;

  const handleOpenModal = (open: boolean) => {
    setModalOpen(open);
  };

  return (
    <Box>
      <Box sx={{ width: 400, margin: "auto" }}>
        <LogoSephoraCollection />
      </Box>

      <Paper sx={{ p: 2 }}>
        {canCreateSeasonalBrief && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => handleOpenModal(true)}
              startIcon={<AddOutlined />}
            >
              {t(LanguageKey.AddNewSeason)}
            </Button>
          </Box>
        )}
        {seasonList
          ? seasonList.map((season, index) => (
              <Box key={season.year}>
                <Typography variant="h6">{season.year}</Typography>
                <SeasonsRows season={season} />
                {index + 1 !== seasonList.length && (
                  <Divider sx={{ my: 2, px: 4 }} />
                )}
              </Box>
            ))
          : [...Array(3)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={200}
                sx={{ my: 1 }}
              />
            ))}
      </Paper>

      {canCreateSeasonalBrief && (
        <ModalLayout
          open={isModalOpen}
          onClose={() => handleOpenModal(false)}
          title={t(NewSeason)}
        >
          <AddSeasonModal />
        </ModalLayout>
      )}
    </Box>
  );
};

export default HomePage;
