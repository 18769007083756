import React from "react";
import {
  type Control,
  Controller,
  type FieldPath,
  type FieldValues,
  type Path,
  type PathValue,
  type UseFormRegister,
  type UseFormSetValue,
  type UseFormWatch,
} from "react-hook-form";

import Carousel from "@components/Carousel/Carousel";
import Dropzone from "@components/Dropzone";
import FileUploader from "@components/FileUploader/FileUploader";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FormListInput } from "@src/components";
import { ACCEPT_FILES_TYPES, FIELD_TYPES, type FieldConfig } from "@src/types";

import dayjs from "dayjs";

interface Props<T extends FieldValues> {
  field: FieldConfig<T>;
  setValue: UseFormSetValue<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  watch: UseFormWatch<T>;
}

const GenericFormField = <T extends FieldValues>({
  field,
  setValue,
  register,
  control,
  watch,
}: Props<T>) => {
  const watchedValue = watch(field.fieldName);

  const handleUpdateArrayValue = (
    fieldName: FieldPath<T>,
    value: PathValue<T, Path<T>>,
  ) => {
    if (Array.isArray(watchedValue)) {
      setValue(
        fieldName,
        [...(watchedValue as Array<File>), value] as PathValue<T, Path<T>>,
        {
          shouldDirty: true,
        },
      );
    } else {
      setValue(fieldName, value, { shouldDirty: true });
    }
  };

  const _renderField = (field: FieldConfig<T>) => {
    const { fieldName, mandatory, label, fieldType, ...props } = field;
    switch (fieldType) {
      case FIELD_TYPES.SELECT:
        return (
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl fullWidth>
                <InputLabel>{`${label}${mandatory ? " *" : ""}`}</InputLabel>
                <Select
                  label={`${label}${mandatory ? " *" : ""}`}
                  error={!!error}
                  {...register(fieldName, {
                    ...(mandatory && { required: `${label} is required` }),
                  })}
                  onChange={onChange}
                  value={value}
                  disabled={field.options.length === 0}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {error?.message && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        );
      case FIELD_TYPES.MULTI_SELECT:
        return (
          <FormListInput
            fieldName={fieldName}
            watch={watch}
            setValue={setValue}
            options={field.options}
            withStatus={field.withStatus}
          />
        );
      case FIELD_TYPES.FILE:
        return (
          <FileUploader
            limit={field.limit || 1}
            accept={field.accept || ACCEPT_FILES_TYPES.ASSORTMENT_FILE}
            handleUploadFiles={(files) =>
              setValue(fieldName, files as PathValue<T, Path<T>>, {
                shouldDirty: true,
              })
            }
            {...props}
          />
        );
      case FIELD_TYPES.DATE: {
        return (
          <Controller
            control={control}
            {...register(fieldName, {
              validate: {
                ...(field.minDate && {
                  minDate: (value) =>
                    value > dayjs() || "Dates must be in the future",
                }),
                ...(field.maxDate && {
                  maxDate: (value) =>
                    value > dayjs() ||
                    `${field.label} must be before ${field.maxDate}`,
                }),
              },
              ...(mandatory && { required: `${label} is required` }),
            })}
            render={({ field: { ...controller }, fieldState: { error } }) => (
              <>
                <DatePicker
                  sx={{ width: "100%" }}
                  {...controller}
                  {...(field.minDate && {
                    minDate: field.minDate,
                  })}
                  {...(field.maxDate && {
                    maxDate: field.maxDate,
                  })}
                  format="DD/MM/YYYY"
                  label={`${label}${mandatory ? " *" : ""}`}
                />
                {error?.message && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </>
            )}
          />
        );
      }
      case FIELD_TYPES.CAROUSEL:
        return (
          <>
            <Dropzone
              title={`${label}${mandatory ? " *" : ""}`}
              accept={field.accept}
              multiple={field.multiple}
              handleUploadFile={(file) =>
                handleUpdateArrayValue(fieldName, [file[0]] as PathValue<
                  T,
                  Path<T>
                >)
              }
              {...(field.limit &&
                field.images && {
                  disabled: field.images.length >= field.limit,
                })}
            />
            {!!field.images?.length && (
              <Carousel
                images={field.images}
                title={`${label}${mandatory ? " *" : ""}`}
                handleClickDelete={(index) =>
                  setValue(
                    fieldName,
                    field.images?.splice(index, 1) as PathValue<T, Path<T>>,
                    {
                      shouldDirty: true,
                    },
                  )
                }
              />
            )}
          </>
        );
      case FIELD_TYPES.BOOLEAN:
        return (
          <FormControlLabel
            disabled={field.disabled}
            control={
              <Checkbox {...register(fieldName)} {...field} size="small" />
            }
            label={field.label}
          />
        );
      case FIELD_TYPES.INPUT_NUMBER:
      case FIELD_TYPES.INPUT_TEXT:
        return (
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type={
                  field.fieldType === FIELD_TYPES.INPUT_TEXT ? "text" : "number"
                }
                label={`${label}${mandatory ? " *" : ""}`}
                error={!!error?.message}
                {...register(fieldName, {
                  ...(mandatory && { required: `${label} is required` }),
                })}
                InputLabelProps={{
                  shrink: !!watchedValue,
                }}
                onChange={(event) => {
                  if (field.onChange) {
                    field.onChange(event);
                  }
                  onChange(event);
                }}
                value={value}
                helperText={error?.message}
              />
            )}
          />
        );
      default:
        return <></>;
    }
  };

  return _renderField(field);
};

export default GenericFormField;
