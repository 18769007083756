import React, { type FC, useRef, useState } from "react";

import {
  StyledDropZone,
  VisuallyHiddenInput,
} from "@components/Dropzone/Dropzone.styled";
import { CloudUploadOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import type { ACCEPT_FILES_TYPES } from "@src/types";
import { ProductText } from "@utils/constants.utils";

import { t } from "i18next";

export interface DropzoneProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  title?: string;
  disabled?: boolean;
  handleUploadFile: (files: FileList) => void;
  error?: boolean;
  accept?: ACCEPT_FILES_TYPES;
  limit?: number;
}

const Dropzone: FC<DropzoneProps> = ({
  title,
  disabled,
  handleUploadFile,
  error,
  accept,
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [highlighted, setHighlighted] = useState(false);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(true);
  };

  const handleDragLeave = () => {
    setHighlighted(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(false);
    const files = event.dataTransfer.files;
    handleUploadFile(files);
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { files },
    } = event;
    if (files) {
      handleUploadFile(files);
    }
  };

  const handleUpload = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <VisuallyHiddenInput
        {...props}
        capture={undefined}
        type="file"
        accept={accept}
        onChange={handleFileInputChange}
        ref={(e) => {
          fileInputRef.current = e;
        }}
      />
      <StyledDropZone
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onChange={handleFileInputChange}
        isError={error}
        isHovered={highlighted}
      >
        <Typography>{title}</Typography>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          disabled={disabled}
          onClick={handleUpload}
          tabIndex={-1}
          sx={{ mt: 2 }}
          startIcon={<CloudUploadOutlined />}
        >
          {t(ProductText.Upload)}
        </Button>
      </StyledDropZone>
    </>
  );
};

export default Dropzone;
