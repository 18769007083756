import {
  type Middleware,
  type MiddlewareAPI,
  isFulfilled,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import type { ApiError } from "@src/types";
import { removeCredentials } from "@store/auth/auth.reducer";
import { type SnackbarProps, addSnackbar } from "@store/common/common.reducer";

const rtkQueryErrorLogger: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    const displayError = (props: Omit<SnackbarProps, "severity">) =>
      dispatch(addSnackbar({ ...props, severity: "error" }));
    const displaySuccess = (props: Omit<SnackbarProps, "severity">) =>
      dispatch(addSnackbar({ ...props, severity: "success" }));

    if (isRejectedWithValue(action)) {
      if (action.payload.status === 401) {
        dispatch(removeCredentials());
      } else if (action.payload.status !== 404) {
        (action.payload.data as ApiError).messages.forEach((message) => {
          displayError({
            message,
          });
        });
      }
    } else if (isFulfilled(action) && action.meta.arg.type === "mutation") {
      displaySuccess({
        message: `success.${action.meta.arg.endpointName}`,
        translation: true,
      });
    }
    return next(action);
  };

export default rtkQueryErrorLogger;
