import { createSlice } from "@reduxjs/toolkit";
import { productApi } from "@store/api/product";

import type { ProductGroupedByLevel } from "types/api/product";

interface ProductState {
  productCreated: boolean;
  productId?: number;
  productsGroupedByLevel?: ProductGroupedByLevel[];
}

const initialState: ProductState = {
  productCreated: false,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductCreated: (state, { payload }: { payload: boolean }) => {
      state.productCreated = payload;
    },
    setProductsGroupedByLevel: (
      state,
      { payload }: { payload: ProductGroupedByLevel[] },
    ) => {
      state.productsGroupedByLevel = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      productApi.endpoints.postProduct.matchFulfilled,
      (state, { payload }) => {
        state.productId = payload.id;
        state.productCreated = true;
      },
    );
    builder.addMatcher(
      productApi.endpoints.getProduct.matchFulfilled,
      (state, { payload }) => {
        state.productId = payload.id;
        state.productCreated = true;
      },
    );
    builder.addMatcher(
      productApi.endpoints.getProductsWithSeason.matchFulfilled,
      (state, { payload }) => {
        state.productsGroupedByLevel = payload;
      },
    );
  },
});

export const { setProductCreated, setProductsGroupedByLevel } =
  productSlice.actions;

export default productSlice.reducer;
