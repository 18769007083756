import React, { type FC, useEffect } from "react";
import { useForm } from "react-hook-form";

import ButtonGrouped from "@components/ButtonGrouped/ButtonGrouped";
import FileUploader from "@components/FileUploader/FileUploader";
import GenericSeasonModal from "@components/Modals/GenericSeasonModal";
import ModalLayout from "@components/Modals/ModalLayout";
import GenericFormField from "@components/inputs/GenericFormField";
import {
  EditOutlined,
  IcecreamOutlined,
  LockOpenOutlined,
  LockOutlined,
  PagesOutlined,
  WebAssetOutlined,
} from "@mui/icons-material";
import { Box, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import type {
  SeasonPutForm,
  SeasonPutRequest,
  SeasonResponse,
} from "@src/types";
import {
  ACCEPT_FILES_TYPES,
  FIELD_TYPES,
  MODAL_ACTION_TYPES,
} from "@src/types";
import {
  useDeleteMerchMutation,
  usePutSeasonMutation,
} from "@store/api/season";
import { useAppSelector } from "@store/hooks";
import { selectAreWeAfterDefDate } from "@store/season/season.selector";
import { convertTimeZoneInDate } from "@utils/fonctions.utils";

import dayjs from "dayjs";

interface Props {
  season: SeasonResponse;
}

const SeasonPageHeader: FC<Props> = ({ season }) => {
  const canCloseOrReopenBrief = useAppSelector(selectAreWeAfterDefDate);

  const [updateSeason] = usePutSeasonMutation();
  const [deleteMerch] = useDeleteMerchMutation();

  const { handleOpenPopup, isPopupOpen, actionType, handleClosePopup } =
    usePopupHandler();

  const {
    control,
    register,
    watch,
    formState: { isDirty, isValid },
    setValue,
    getValues,
  } = useForm<SeasonPutForm>({
    defaultValues: {
      deadlineDef: dayjs(season.deadlineDef),
      deadlineNoDef: dayjs(season.deadlineNoDef),
      cleanName: season.cleanName,
      merchAnnexes: season.merchAnnexes,
    },
  });

  const { deadlineDef, deadlineNoDef, merchAnnexes } = watch();

  const handleDeleteMerchAnnex = (index: number) => {
    if (merchAnnexes) {
      deleteMerch({
        name: season.name,
        merchAnnex: merchAnnexes[index].name,
      });
    }
  };

  useEffect(() => {
    if (isDirty && isValid) {
      const formValues = getValues();
      const seasonData: SeasonPutRequest = {
        year: season.year,
        cleanName: season.cleanName,
        deadlineDef: formValues.deadlineDef.toDate(),
        deadlineNoDef: formValues.deadlineNoDef.toDate(),
        merchAnnexes: formValues.merchAnnexes?.filter(
          (merchAnnex) => merchAnnex instanceof File,
        ) as File[],
      };
      updateSeason(seasonData);
    }
  }, [deadlineDef, deadlineNoDef, merchAnnexes]);

  return (
    <>
      <Grid
        container
        m={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="auto"
      >
        <Grid item display="flex">
          <Stack spacing={2}>
            <Box alignItems="center" display="flex" flexDirection="row">
              <Typography variant="h4" mr={2}>
                {season.name}
              </Typography>
              <Grid>
                {season.isClosed ? (
                  <LockOutlined color="error" />
                ) : (
                  <LockOpenOutlined color="success" />
                )}
              </Grid>
            </Box>

            <Grid
              flexDirection="row"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <EditOutlined width={18} />
              <Typography>{`Last update: ${convertTimeZoneInDate(season?.updateDate)}`}</Typography>
            </Grid>
            <Grid
              flexDirection="row"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <PagesOutlined width={18} />
              <Typography>Merch annexes :</Typography>
              <FileUploader
                handleUploadFiles={(files) =>
                  setValue("merchAnnexes", files as File[], {
                    shouldDirty: true,
                  })
                }
                handleDeleteFile={(index) => {
                  handleDeleteMerchAnnex(index);
                  setValue("merchAnnexes", merchAnnexes?.splice(index, 1), {
                    shouldDirty: true,
                  });
                }}
                files={merchAnnexes}
                limit={5}
                accept={ACCEPT_FILES_TYPES.MERCH_ANNEXES}
                disabled
              />
            </Grid>
          </Stack>
        </Grid>
        <Grid item display="flex" flexDirection="row" gap={2}>
          <Grid item>
            <form>
              <Grid
                item
                justifyContent="space-evenly"
                display="flex"
                flexDirection="column"
                rowGap={2}
              >
                <GenericFormField<SeasonPutForm>
                  field={{
                    fieldName: "deadlineNoDef",
                    fieldType: FIELD_TYPES.DATE,
                    label: "deadlineNoDef",
                    mandatory: true,
                    minDate: dayjs(),
                    maxDate: deadlineDef,
                  }}
                  setValue={setValue}
                  register={register}
                  control={control}
                  watch={watch}
                />
                <GenericFormField
                  field={{
                    fieldName: "deadlineDef",
                    fieldType: FIELD_TYPES.DATE,
                    label: "deadlineDef",
                    mandatory: true,
                    minDate: deadlineNoDef,
                  }}
                  setValue={setValue}
                  register={register}
                  control={control}
                  watch={watch}
                />
              </Grid>
            </form>
          </Grid>
          <Divider orientation="vertical" />
          <Grid
            item
            justifyContent="space-evenly"
            display="flex"
            flexDirection="column"
          >
            <Grid
              flexDirection="row"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <IcecreamOutlined />
              <Typography>{`${season.nbProducts} products`}</Typography>
              {season.nbModifiedProducts > 0 && (
                <Chip
                  color="warning"
                  label={`${season.nbModifiedProducts} modified products`}
                />
              )}
            </Grid>
            <Grid
              flexDirection="row"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <WebAssetOutlined />
              <Typography>{`${season.nbAssets} assets`}</Typography>
              {season.nbModifiedAssets > 0 && (
                <Chip
                  color="warning"
                  label={`${season.nbModifiedAssets} modified assets`}
                />
              )}
            </Grid>
          </Grid>

          {canCloseOrReopenBrief && (
            <Grid>
              <ButtonGrouped
                options={[
                  {
                    label: season.isClosed
                      ? "Reopen the brief"
                      : "Close the brief",
                    onClick: () =>
                      handleOpenPopup(
                        season.isClosed
                          ? MODAL_ACTION_TYPES.REOPEN_BRIEF
                          : MODAL_ACTION_TYPES.CLOSE_BRIEF,
                      ),
                  },
                ]}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <ModalLayout
        open={isPopupOpen}
        onClose={handleClosePopup}
        title={actionType}
      >
        <GenericSeasonModal
          cleanName={season.cleanName}
          actionType={actionType}
          onClose={handleClosePopup}
        />
      </ModalLayout>
    </>
  );
};

export default SeasonPageHeader;
