import type {
  AssetFileValueRequest,
  AssetValue,
  AxisDetails,
} from "@src/types";
import { AssetContentFieldStatus, type SelectOptionType } from "@src/types";

import dayjs from "dayjs";
import type { Asset, AssetForm } from "types/api/asset";
import { type CategoriesDetails } from "types/api/category";
import { type SubCategoriesDetails } from "types/api/subCategory";

export const generateYearsOptions = (nb = 10): SelectOptionType[] => {
  const currentYear = dayjs().year();
  return Array.from(Array(nb)).map((_, i) => ({
    value: (currentYear + i).toString(),
    label: (currentYear + i).toString(),
  }));
};

export function convertTimeZoneInDate(
  hourWithTimeZone: Date | undefined,
  forInput = false,
): string {
  if (hourWithTimeZone) {
    const date = dayjs(hourWithTimeZone);
    if (forInput) {
      return date.format("YYYY-MM-DD");
    }
    return date.format("DD/MM/YYYY");
  }

  return "";
}

export function toPascalCaseWithSpaces(text: string) {
  const words = text.split(" ");

  const pascalCaseWords = words.map((word) => {
    if (word.trim() === "") return "";
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return pascalCaseWords.join(" ");
}

export function updateNestedField<T extends object, K extends keyof T>(
  obj: T,
  field: K,
  nestedField: keyof T[K],
  value: any,
): T {
  return {
    ...obj,
    [field]: {
      ...obj[field],
      [nestedField]: value,
    },
  };
}

export const cleanString = (s: string) =>
  s
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+/g, "")
    .replace(/((?=(-+))\2)$/g, "");

export function getNameFromId(
  id: number,
  array?: AxisDetails[] | CategoriesDetails[] | SubCategoriesDetails[],
): string {
  return array?.find((element) => element.id === id)?.name || "";
}

export const getIdFromName = <T extends { name: string; id: number }>(
  name: string,
  array: T[],
) => array.find((elt) => elt.name === name)?.id || 0;

export function removeAlphaCharacters(value: string): string {
  return value.replace(/[^\d]/g, "");
}

export enum keyboardDigitInputs {
  ZERO = "0",
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
  SIX = "6",
  SEVEN = "7",
  EIGHT = "8",
  NINE = "9",
}

export enum keyboardInputs {
  DELETE = "Delete",
  BACKSPACE = "Backspace",
  SHIFT = "Shift",
  TAB = "Tab",
  ARROW_RIGHT = "ArrowRight",
  ARROW_LEFT = "ArrowLeft",
  ENTER = "Enter",
}

export const isDigit = (value: string) => {
  // We cannot use regex because of japanese keyboard (iOS 11 and 12)
  return Object.values(keyboardDigitInputs).includes(
    value as keyboardDigitInputs,
  );
};

export const isAllowedKey = (value: string) => {
  return Object.values(keyboardInputs).includes(value as keyboardInputs);
};

export const avoidAlphaCharacters = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (!(isDigit(event.key) || isAllowedKey(event.key))) {
    event.preventDefault();
  }
};

export const getOnlyValueFromAsset = (asset: Asset): AssetForm => {
  const emptyAssetValue = {
    value: "",
    status: AssetContentFieldStatus.NULL,
  };

  return {
    new_tag: { value: asset.content.new_tag.value || [] },
    comment: asset.content.comment.value || emptyAssetValue,
    nb_of_shades_to_shoot:
      asset.content.nb_of_shades_to_shoot.value || emptyAssetValue,
    nb_of_carnations: asset.content.nb_of_carnations.value || emptyAssetValue,
    total_nb_of_assets:
      asset.content.total_nb_of_assets.value || emptyAssetValue,
    skus: asset.content.skus.value || emptyAssetValue,
    shades: asset.content.shades.value || emptyAssetValue,
    bench_visuals: { value: asset.content.bench_visuals.value || [] },
    comment_from_marketing_product:
      asset.content.comment_from_marketing_product.value || emptyAssetValue,
    geographic_scope: { value: asset.content.geographic_scope.value || [] },
    ppage: { value: asset.content.ppage.value || [] },
    digital_animation: { value: asset.content.digital_animation.value || [] },
    media_regional_context:
      asset.content.media_regional_context.value || emptyAssetValue,
    media_traditional_tv_dooh_ooh: {
      value: asset.content.media_traditional_tv_dooh_ooh.value || [],
    },
    media_digital: { value: asset.content.media_digital.value || [] },
    permanent_instore: { value: asset.content.permanent_instore.value || [] },
    temporary_instore: { value: asset.content.temporary_instore.value || [] },
  };
};

export function getDirtyValues<
  DirtyFields extends Record<string, unknown>,
  Values extends Record<keyof DirtyFields, unknown>,
>(
  dirtyFields: DirtyFields,
  values: Values,
  prevValues: Values,
  useStatus: boolean,
): Partial<typeof values> {
  return Object.keys(dirtyFields).reduce((prev, key) => {
    if (!dirtyFields[key]) return prev;

    if (key === "value") {
      if (Array.isArray(values[key])) {
        return values[key] as AssetValue[];
      }
      return {
        ...prev,
        value: values[key],
        status: useStatus
          ? AssetContentFieldStatus.MODIFIED
          : AssetContentFieldStatus.NULL,
      };
    }
    return {
      ...prev,
      [key]: getDirtyValues(
        dirtyFields[key] as DirtyFields,
        values[key] as Values,
        prevValues[key] as Values,
        useStatus,
      ),
    };
  }, {});
}

export const consolidFileArray = (
  fileList: FileList,
  useStatus: boolean,
): AssetFileValueRequest[] => {
  return Array.from(fileList).map((file) => {
    if (useStatus) {
      return {
        value: file,
        status: AssetContentFieldStatus.CREATED,
      };
    }
    return {
      value: file,
      status: AssetContentFieldStatus.NULL,
    };
  });
};

export const kebabize = (str: string): string =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? "-" : "") + $.toLowerCase(),
  );
