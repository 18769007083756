import { Box, styled as MUIstyled } from "@mui/material";

import styled from "styled-components";

interface IStyledImgTransition {
  currentIndex: number;
}

export const StyledImgUploadCarouselContent = MUIstyled(Box, {
  shouldForwardProp: (prop) => prop !== "maxPage",
})<{ maxImagesShown?: number }>(({ maxImagesShown = 1, theme }) => ({
  display: "flex",
  position: "relative",
  textAlign: "center",
  width: `${100 / maxImagesShown}%`,
  minWidth: `${100 / maxImagesShown}%`,
  maxHeight: 140,
  overflow: "hidden",
  justifyContent: "center",
  alignItems: "flex-start",
  transition: "all 0.3s ease",
  paddingRight: theme.spacing(1),
}));

export const StyledTransitionImgCarousel = styled.div<IStyledImgTransition>`
  display: flex;
  transition: transform 0.3s ease;
  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 100}%)`};
  overflow: hidden;
`;

export const StyledDeleteImageBtnContainer = styled.div`
  display: block;
  position: absolute;
  z-index: 2;
  top: 4px;
  right: 4px;
`;
