import React, { type FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";

import Carousel from "@components/Carousel/Carousel";
import FileUploader from "@components/FileUploader/FileUploader";
import ProductActions from "@components/ProductActions";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import type { ProductPutForm, SeasonsGlobalInformations } from "@src/types";
import { ACCEPT_FILES_TYPES, Authority, MODAL_ACTION_TYPES } from "@src/types";
import {
  useDeleteAdditionalPhotosProductMutation,
  useDeletePackshotProductMutation,
  usePutProductMutation,
} from "@store/api/product";
import { selectCategoryType } from "@store/category/category.selector";
import { useAppSelector } from "@store/hooks";
import {
  selectCanModifySeasonData,
  selectSeasons,
} from "@store/season/season.selector";
import type { RootState } from "@store/store";
import { LevelOfActivationOption } from "@utils/constants.utils";

import type { CategoriesDetails } from "types/api/category";
import type { ProductGetResponse } from "types/api/product";
import type { SubCategoriesDetails } from "types/api/subCategory";

interface ProductHeaderProps {
  product: ProductGetResponse;
}

const ProductPageHeaderData: FC<ProductHeaderProps> = ({ product }) => {
  const navigate = useNavigate();

  const [updateProduct] = usePutProductMutation();

  const seasons = useAppSelector(selectSeasons);
  const categories = useAppSelector(selectCategoryType);
  const canUpdateProduct = useAppSelector((state: RootState) =>
    selectCanModifySeasonData(state, Authority.UPDATE_PRODUCT_BRIEF),
  );
  const [deletePackshot] = useDeletePackshotProductMutation();
  const [deleleteAdditionalPhotos] = useDeleteAdditionalPhotosProductMutation();

  const [season, setSeason] = useState<SeasonsGlobalInformations>();
  const [category, setCategory] = useState<CategoriesDetails | undefined>(
    undefined,
  );
  const [subCategory, setSubCategory] = useState<
    SubCategoriesDetails | undefined
  >(undefined);

  const {
    register,
    setValue,
    watch,
    control,
    formState: { isDirty },
  } = useForm<ProductPutForm>({
    defaultValues: {
      name: product.name,
      numberOfSkus: product.numberOfSkus,
      sellOutAmbition: product.sellOutAmbition,
      level: product.level,
      assortmentFile: product.assortmentFile,
      packshots: product.packshots,
      additionalPhotos: product.additionalPhotos,
      newPackaging: product.newPackaging,
      elementToHighlight: product.elementToHighlight,
      mopOwner: product.mopOwner,
      digitalOwner: product.digitalOwner,
      productOwner: product.productOwner,
      imageProductOwner: product.imageProductOwner,
      imageModelShotOwner: product.imageModelShotOwner,
    },
  });

  const { packshots, assortmentFile, additionalPhotos, ...formValues } =
    watch();
  const canUpdateAsset = useAppSelector((state) =>
    selectCanModifySeasonData(state, Authority.UPDATE_ASSET),
  );

  const { actionType } = usePopupHandler();

  useEffect(() => {
    categories.map((categoryItem) => {
      const subCategory = categoryItem.subCategories.find(
        (subCategoryItem) => subCategoryItem.id === product.subCategoryId,
      );
      if (subCategory) {
        setCategory(category);
        setSubCategory(subCategory);
      }
    });
    seasons.map((yearItem) => {
      yearItem.seasons.map((seasonItem) => {
        const season = seasonItem.elements.find(
          (element) => element.id === product.seasonId,
        );
        if (season) {
          setSeason(season);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (season) {
      if (actionType === MODAL_ACTION_TYPES.DELETE_PRODUCT) {
        const redirectWithParams = generatePath("/season/:cleanName", {
          cleanName: season.cleanName,
        });
        navigate(redirectWithParams);
      }
    }
  }, []);

  const handleDeleteAdditionalPhoto = (index: number) => {
    if (additionalPhotos) {
      deleleteAdditionalPhotos({
        name: additionalPhotos[index].name,
        productId: product.id,
      });
    }
  };

  const handleDeletePackshot = (index: number) => {
    if (packshots) {
      deletePackshot({ name: packshots[index].name, productId: product.id });
    }
  };

  const onSubmit = async () => {
    if (season) {
      updateProduct({
        ...product,
        ...formValues,
        assortmentFile,
        additionalPhotos: additionalPhotos?.filter(
          (additionalPhoto) => additionalPhoto instanceof File,
        ) as File[],
        packshots: packshots?.filter(
          (packshot) => packshot instanceof File,
        ) as File[],
        seasonName: season.name,
      });
    }
  };

  useEffect(() => {
    if (isDirty) {
      onSubmit();
    }
  }, Object.values(formValues));

  return (
    <form>
      <Grid container justifyContent="space-between" display="flex">
        <Grid
          container
          gap={2}
          display="flex"
          mb={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            {canUpdateProduct ? (
              <TextField
                label="Name"
                type="text"
                variant="standard"
                fullWidth
                {...register("name", {
                  required: "Name is required",
                })}
              />
            ) : (
              <>
                <Typography variant="caption">{product.name}</Typography>
                <Typography variant="h5">{product.name}</Typography>
              </>
            )}
          </Grid>
          {product.isCancel && (
            <Tooltip title={product.whyIsCancel}>
              <CancelOutlinedIcon />
            </Tooltip>
          )}

          <Grid item>
            <Typography variant="caption">Season</Typography>
            <Typography>{season?.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Category</Typography>
            <Typography>
              {category?.name === subCategory?.name
                ? category?.name
                : `${category?.name} - ${subCategory?.name}`}
            </Typography>
          </Grid>
          <Grid item>
            {canUpdateProduct ? (
              <>
                <InputLabel>Level</InputLabel>
                <Select
                  fullWidth
                  variant="standard"
                  {...register("level", {
                    required: "Level is required",
                  })}
                  defaultValue={product.level}
                >
                  {LevelOfActivationOption.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : (
              <>
                <Typography variant="caption">Level</Typography>
                <Typography>{product.level}</Typography>
              </>
            )}
          </Grid>
          <Grid item>
            <Typography variant="caption">Status</Typography>
            <Typography> {product.status}</Typography>
          </Grid>
          <Grid item>
            {canUpdateProduct ? (
              <TextField
                label="Number of SKUs:"
                type="number"
                variant="standard"
                fullWidth
                {...register("numberOfSkus", {
                  required: "numberOfSkus is required",
                })}
              />
            ) : (
              <>
                <Typography variant="caption">Number of SKUs :</Typography>
                <Typography>{product.numberOfSkus}</Typography>
              </>
            )}
          </Grid>
          <Grid item>
            {canUpdateProduct ? (
              <TextField
                label="Sell Out Ambitions :"
                type="text"
                variant="standard"
                fullWidth
                {...register("sellOutAmbition", {
                  required: "sellOutAmbition is required",
                })}
              />
            ) : (
              <>
                <Typography variant="caption">Sell Out Ambitions :</Typography>
                <Typography>{product.sellOutAmbition}</Typography>
              </>
            )}
          </Grid>
          <Grid item>
            {canUpdateProduct ? (
              <TextField
                label="Benefit/Scent :"
                type="text"
                variant="standard"
                fullWidth
                {...register("benefit", {
                  required: "Benefit/Scent is required",
                })}
              />
            ) : (
              <>
                <Typography variant="caption">Benefit/Scent :</Typography>
                <Typography>{product.benefit}</Typography>
              </>
            )}
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Controller
                  name="newPackaging"
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                        setValue("newPackaging", e.target.checked, {
                          shouldDirty: true,
                        });
                      }}
                      disabled={!canUpdateAsset}
                    />
                  )}
                />
              }
              label="New packaging"
            />
          </Grid>
          <Grid item>
            <ProductActions
              id={product.id}
              actions={[
                MODAL_ACTION_TYPES.DUPLICATE_PRODUCT,
                MODAL_ACTION_TYPES.CANCEL,
                MODAL_ACTION_TYPES.DELETE_PRODUCT,
              ]}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item display="flex" alignItems="flex-start" gap={4}>
            <Grid item>
              <Typography variant="caption">Assortment File :</Typography>
              <FileUploader
                {...(assortmentFile && { files: [assortmentFile] })}
                handleUploadFiles={(files) =>
                  setValue("assortmentFile", files[0], { shouldDirty: true })
                }
                mandatory
                accept={ACCEPT_FILES_TYPES.ASSORTMENT_FILE}
                limit={1}
                handleDeleteFile={(index) => {
                  handleDeletePackshot(index);
                  setValue("assortmentFile", undefined, {
                    shouldDirty: true,
                  });
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="caption">Additional Photos :</Typography>
              <FileUploader
                files={additionalPhotos}
                handleUploadFiles={(files) =>
                  setValue("additionalPhotos", files, { shouldDirty: true })
                }
                accept={ACCEPT_FILES_TYPES.ADDITIONAL_PHOTOS}
                limit={5}
                handleDeleteFile={(index) => {
                  handleDeleteAdditionalPhoto(index);
                  setValue(
                    "additionalPhotos",
                    additionalPhotos?.splice(index, 1),
                    {
                      shouldDirty: true,
                    },
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container display="flex" justifyContent="space-between" gap={1}>
        <Grid item display="flex" alignItems="center" xs={5}>
          <Grid item>
            <Carousel
              title="Packshots"
              maxImagesShown={1}
              disabled={!canUpdateProduct}
              images={packshots}
              handleClickDelete={(index) =>
                setValue("packshots", packshots?.splice(index, 1), {
                  shouldDirty: true,
                })
              }
            />
          </Grid>
          <Grid item>
            <FileUploader
              handleUploadFiles={(files) => {
                if (Array.isArray(packshots)) {
                  setValue("packshots", [...packshots, ...files], {
                    shouldDirty: true,
                  });
                } else {
                  setValue("packshots", files, { shouldDirty: true });
                }
              }}
              accept={ACCEPT_FILES_TYPES.PACKSHOTS}
              limit={10}
            />
          </Grid>
        </Grid>
        <Grid item display="flex" flexDirection="column" xs={3}>
          <Grid item>
            {canUpdateProduct ? (
              <TextField
                label="Key elements to highlight in visuals:"
                type="text"
                variant="standard"
                size="small"
                fullWidth
                {...register("elementToHighlight", {
                  required: "elementToHighlight is required",
                })}
              />
            ) : (
              <>
                <Typography variant="caption">
                  Key elements to highlight in visuals :
                </Typography>
                <Typography>{product.elementToHighlight}</Typography>
              </>
            )}
          </Grid>
          <Grid item>
            {canUpdateProduct ? (
              <TextField
                label="MOP owner :"
                type="text"
                variant="standard"
                size="small"
                fullWidth
                {...register("mopOwner", {
                  required: "mopOwner is required",
                })}
              />
            ) : (
              <>
                <Typography variant="caption">MOP owner :</Typography>
                <Typography>{product.mopOwner}</Typography>
              </>
            )}
          </Grid>
          <Grid item>
            {canUpdateProduct ? (
              <TextField
                label="Digital owner :"
                type="text"
                variant="standard"
                size="small"
                fullWidth
                {...register("digitalOwner", {
                  required: "digitalOwner is required",
                })}
              />
            ) : (
              <>
                <Typography variant="caption">Digital owner :</Typography>
                <Typography>{product.digitalOwner}</Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item display="flex" flexDirection="column" xs={3}>
          <Grid item>
            {canUpdateProduct ? (
              <TextField
                label="Marketing Product owner :"
                type="text"
                variant="standard"
                size="small"
                fullWidth
                {...register("productOwner", {
                  required: "productOwner is required",
                })}
              />
            ) : (
              <>
                <Typography variant="caption">
                  Marketing Product owner :
                </Typography>
                <Typography>{product.productOwner}</Typography>
              </>
            )}
          </Grid>
          <Grid item>
            {canUpdateProduct ? (
              <TextField
                label="Model shot image owner :"
                type="text"
                variant="standard"
                size="small"
                fullWidth
                {...register("imageModelShotOwner", {
                  required: "imageModelShotOwner is required",
                })}
              />
            ) : (
              <>
                <Typography variant="caption">
                  Model shot image owner :
                </Typography>
                <Typography>{product.imageModelShotOwner}</Typography>
              </>
            )}
          </Grid>
          <Grid item>
            {canUpdateProduct ? (
              <TextField
                label="Product image owner :"
                type="text"
                variant="standard"
                size="small"
                fullWidth
                {...register("imageProductOwner", {
                  required: "imageProductOwner is required",
                })}
              />
            ) : (
              <>
                <Typography variant="caption">Product image owner :</Typography>
                <Typography>{product.imageProductOwner}</Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProductPageHeaderData;
