import React, { type FC, Fragment, useState } from "react";

import {
  CloseFullscreenOutlined,
  ExpandLess,
  ExpandMore,
  OpenInFull,
} from "@mui/icons-material";
import {
  Collapse,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import ProductCard from "@pages/SeasonPage/components/ProductsList/components/ProductCard";
import type { Category, ProductGroupedByLevel } from "@src/types";
import { ProductText } from "@utils/constants.utils";

interface Props {
  product: ProductGroupedByLevel;
}

const ProductsListItem: FC<Props> = ({ product }) => {
  const [isOpenLevel, setIsOpenLevel] = useState(true);
  const [openedCategories, setOpenedCategories] = useState<string[]>(
    product.categories.map((category) => category.category),
  );
  const totalProducts = product.categories.reduce(
    (acc, category) => acc + category.products.length,
    0,
  );

  const handleToggleCategory = (category: Category) => {
    if (openedCategories.includes(category.category)) {
      setOpenedCategories((prevState) =>
        prevState.filter((cat) => cat !== category.category),
      );
    } else {
      setOpenedCategories((prevState) => [...prevState, category.category]);
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Grid
          container
          item
          gap={2}
          width="100%"
          display="flex"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">{product.level}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {totalProducts +
                (totalProducts > 1
                  ? ProductText.Products
                  : ProductText.Product)}
            </Typography>
          </Grid>
          {product.nbModifiedProducts > 0 && (
            <>
              <Grid item>-</Grid>
              <Grid item>
                <Typography variant="caption">
                  {`${product.nbModifiedProducts} modified products`}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item gap={2} justifyContent="flex-end" display="flex">
            <Grid item>
              <IconButton
                onClick={() =>
                  setOpenedCategories(
                    product.categories.map((category) => category.category),
                  )
                }
              >
                <OpenInFull />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setOpenedCategories([])}>
                <CloseFullscreenOutlined />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => setIsOpenLevel((prevState) => !prevState)}
              >
                {isOpenLevel ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={isOpenLevel} timeout="auto" unmountOnExit sx={{ m: 2 }}>
        {product.categories.map((category) => (
          <Fragment key={category.category}>
            <Grid
              container
              display="flex"
              alignItems="center"
              flexDirection="row"
              gap={2}
            >
              <Grid item>
                <IconButton onClick={() => handleToggleCategory(category)}>
                  {openedCategories.includes(category.category) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">{category.category}</Typography>
              </Grid>
            </Grid>
            <Collapse
              in={openedCategories.includes(category.category)}
              timeout="auto"
              unmountOnExit
              sx={{ m: 2 }}
            >
              <Stack
                direction="row"
                gap={2}
                useFlexGap
                sx={{ flexWrap: "wrap" }}
              >
                {category.products.map((productPreview) => (
                  <ProductCard
                    productPreview={productPreview}
                    key={productPreview.id}
                  />
                ))}
              </Stack>
            </Collapse>
          </Fragment>
        ))}
      </Collapse>
    </Paper>
  );
};

export default ProductsListItem;
