import React, { type FC, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import type { DeliverRequest } from "@src/types";
import { useDeliverAssetMutation } from "@store/api/asset";

interface Props {
  onClose: () => void;
  id: number;
  isDelivered?: boolean;
}

const DeliverModal: FC<Props> = ({ onClose, id, isDelivered = false }) => {
  const [deliverAsset, { isLoading, isSuccess }] = useDeliverAssetMutation();
  const {
    register,
    watch,
    trigger,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<DeliverRequest>({
    mode: "onChange",
    defaultValues: {
      whyNotDelivered: "",
    },
  });

  const whyNotDelivered = watch("whyNotDelivered");

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onSubmit = async () => {
    if (!isDelivered) {
      await trigger();
    }
    if (isValid) {
      deliverAsset({
        id,
        whyNotDelivered,
        isDelivered,
      });
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {!isDelivered && (
        <Box my={4}>
          <TextField
            label="Why is this asset not delivery ? *"
            type="text"
            fullWidth
            error={!!errors.whyNotDelivered}
            {...register("whyNotDelivered", {
              required: "Reason is required",
            })}
          />
          {errors.whyNotDelivered && (
            <FormHelperText error>
              {errors.whyNotDelivered?.message}
            </FormHelperText>
          )}
        </Box>
      )}

      <Typography>
        {isDelivered
          ? "Do you want to change asset status to: not delivered ?"
          : "Do you want to change asset status to: delivered ?"}
      </Typography>

      <Button disabled={isLoading} type="submit">
        Confirm
      </Button>
    </form>
  );
};

export default DeliverModal;
