import { useState } from "react";
import { useDispatch } from "react-redux";

import { MODAL_ACTION_TYPES } from "@src/types";
import { addSnackbar } from "@store/common/common.reducer";
import { useAppSelector } from "@store/hooks";
import { selectAreWeBeforeNoDefDate } from "@store/season/season.selector";

const usePopupHandler = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [actionType, setActionType] = useState<MODAL_ACTION_TYPES>(
    MODAL_ACTION_TYPES.NONE,
  );
  const dispatch = useDispatch();
  const isProductOrAssetDeletable = useAppSelector(selectAreWeBeforeNoDefDate);

  const handleOpenPopup = (action: MODAL_ACTION_TYPES) => {
    if (
      [
        MODAL_ACTION_TYPES.DELETE_PRODUCT,
        MODAL_ACTION_TYPES.DELETE_ASSET,
      ].includes(action) &&
      !isProductOrAssetDeletable
    ) {
      dispatch(
        addSnackbar({
          message:
            "Deletion is not possible because brief asset non def deadline is passed",
          severity: "warning",
        }),
      );
    } else {
      setActionType(action);
      setPopupOpen(true);
    }
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return {
    isPopupOpen,
    actionType,
    handleOpenPopup,
    handleClosePopup,
  };
};

export default usePopupHandler;
