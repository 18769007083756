import React, { type FC, useState } from "react";

import {
  StyledDeleteImageBtnContainer,
  StyledImgUploadCarouselContent,
  StyledTransitionImgCarousel,
} from "@components/Carousel/Carousel.styled";
import ImageComponent from "@components/Image";
import { ArrowLeft, ArrowRight, DeleteOutline } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import type {
  AssetFileValueResponse,
  FileComponentGetDetails,
} from "@src/types";

export interface CarouselProps {
  title?: string;
  images?:
    | string[]
    | File[]
    | FileComponentGetDetails[]
    | AssetFileValueResponse[]
    | (File | FileComponentGetDetails)[];
  disabled?: boolean;
  maxImagesShown?: number;
  handleClickDelete?: (index: number) => void;
}

const Carousel: FC<CarouselProps> = ({
  title,
  images = [],
  disabled,
  maxImagesShown = 4,
  handleClickDelete,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const removeImage = (index: number) => {
    if (currentIndex >= images.length - 1 && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
    if (handleClickDelete) {
      handleClickDelete(index);
    }
  };

  const handleScroll = (nb: number) => {
    const maxPage = Math.ceil(images.length / maxImagesShown);
    setCurrentIndex((prevIndex) =>
      prevIndex + nb > maxPage
        ? 0
        : prevIndex + nb < 0
          ? maxPage
          : prevIndex + nb,
    );
  };

  const getImageUrl = (image: (typeof images)[number]) => {
    if (typeof image === "string") {
      return image;
    } else if ("value" in image) {
      if (image.value instanceof File) {
        return URL.createObjectURL(image.value); // asset - images that just got uploaded
      } else if ("url" in image.value) {
        return image.value.url; // asset - images from api response
      }
    } else if (image instanceof File) {
      return URL.createObjectURL(image);
    } else if ("url" in image) {
      return image.url; // images from api response
    }
  };

  return (
    <Box>
      <>
        {title && (
          <Divider sx={{ mt: 2 }}>
            <Typography variant="caption">{title}</Typography>
          </Divider>
        )}
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 1 }}
        >
          {images.length > 0 && (
            <Grid item display="flex" xs={1}>
              <IconButton
                disabled={!(images.length > maxImagesShown)}
                onClick={() => {
                  handleScroll(-1);
                }}
              >
                <ArrowLeft />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={10} sx={{ overflow: "hidden" }}>
            <StyledTransitionImgCarousel currentIndex={currentIndex}>
              {images.map((image, i) => (
                <StyledImgUploadCarouselContent
                  key={i}
                  maxImagesShown={maxImagesShown}
                >
                  {!disabled && (
                    <StyledDeleteImageBtnContainer>
                      <IconButton onClick={() => removeImage(i)}>
                        <DeleteOutline />
                      </IconButton>
                    </StyledDeleteImageBtnContainer>
                  )}
                  <ImageComponent url={getImageUrl(image)} />
                </StyledImgUploadCarouselContent>
              ))}
            </StyledTransitionImgCarousel>
          </Grid>
          {images.length > 0 && (
            <Grid item xs={1}>
              <IconButton
                disabled={!(images.length > maxImagesShown)}
                onClick={() => {
                  handleScroll(1);
                }}
              >
                <ArrowRight />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </>
    </Box>
  );
};

export default Carousel;
