import React from "react";
import type {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import GenericFormField from "@components/inputs/GenericFormField";
import {
  Authority,
  FIELD_TYPES,
  type FieldConfig,
  FieldTypes,
} from "@src/types";
import { selectRoles } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectCanModifySeasonData } from "@store/season/season.selector";

import type {
  AssetForm,
  BooleanAssetContent,
  ListAssetContent,
  TextAssetContent,
} from "types/api/asset";

interface ContentAssetProps {
  field: BooleanAssetContent | ListAssetContent | TextAssetContent;
  register: UseFormRegister<AssetForm>;
  useStatus: boolean;
  control: Control<AssetForm>;
  watch: UseFormWatch<AssetForm>;
  setValue: UseFormSetValue<AssetForm>;
  disabled?: boolean;
}

const AssetFormField: React.FC<ContentAssetProps> = ({
  field,
  register,
  useStatus,
  control,
  watch,
  setValue,
  disabled,
}) => {
  const canUpdateAsset = useAppSelector((state) =>
    selectCanModifySeasonData(state, Authority.UPDATE_ASSET),
  );
  const userRoles = useAppSelector(selectRoles);

  const isDisabled =
    disabled ||
    !canUpdateAsset ||
    field.editable === "NO" ||
    ("editableBy" in field &&
      field.editable === "YES" &&
      !field.editableBy.some((role) => userRoles.includes(role)));

  const setGenericFieldProps = (): FieldConfig<AssetForm> => {
    switch (field.type) {
      case FieldTypes.BOOLEAN:
        return {
          fieldName: `${field.jsonName as keyof AssetForm}.value`,
          fieldType: FIELD_TYPES.BOOLEAN,
          label: field.name,
          disabled: isDisabled,
          mandatory: field.mandatory,
          defaultChecked: field.value.value,
        };
      case FieldTypes.LIST:
        return {
          fieldName: `${field.jsonName as keyof AssetForm}.value`,
          fieldType: FIELD_TYPES.MULTI_SELECT,
          label: field.name,
          options: field.options,
          disabled: isDisabled,
          mandatory: field.mandatory,
          withStatus: useStatus,
        };
      case FieldTypes.TEXT:
      default:
        return {
          fieldName: `${field.jsonName as keyof AssetForm}.value`,
          fieldType: FIELD_TYPES.INPUT_TEXT,
          mandatory: field.mandatory,
          label: field.name,
          disabled: isDisabled,
          ...("placeholder" in field && {
            placeholder: field.placeholder,
          }),
        };
    }
  };

  return (
    <GenericFormField
      field={setGenericFieldProps()}
      register={register}
      control={control}
      watch={watch}
      setValue={setValue}
    />
  );
};

export default AssetFormField;
