import React, { type FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import GenericFormField from "@components/inputs/GenericFormField";
import { Button, Chip, Grid, Stack } from "@mui/material";
import type { AssetPostForm, AssetPostRequest, FieldConfig } from "@src/types";
import { ACCEPT_FILES_TYPES, FIELD_TYPES } from "@src/types";
import { usePostAssetMutation } from "@store/api/asset";
import { useGetAvailableNewAssetQuery } from "@store/api/product";
import {
  CarrouselText,
  ProductText,
  initAsset,
  possibleValues,
} from "@utils/constants.utils";
import type { CommonAssetType } from "@utils/enum.utils";

interface Props {
  productId: number;
}

const AddAssetModal: FC<Props> = ({ productId }) => {
  const { t } = useTranslation();
  const [postAsset, { isLoading }] = usePostAssetMutation();

  const [typeOption, setTypeOption] = useState<CommonAssetType[]>([]);
  const { data: availableAsset } = useGetAvailableNewAssetQuery({
    id: productId,
  });
  const {
    watch,
    handleSubmit,
    control,
    register,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm<AssetPostForm>({
    mode: "onBlur",
    defaultValues: { ...initAsset, productId },
  });

  const formValues = watch();

  useEffect(() => {
    if (availableAsset) {
      setTypeOption(availableAsset);
    }
  }, [availableAsset]);

  const onSubmit = async ({ type, productId, ...form }: AssetPostForm) => {
    await trigger();
    if (isValid) {
      const assetPost: AssetPostRequest = {
        type,
        productId,
        content: form,
        bench_visuals: formValues.bench_visuals?.filter(
          (benchVisual) => benchVisual instanceof File,
        ) as File[],
      };
      postAsset(assetPost);
    }
  };

  const [currentStep, setCurrentStep] = useState(0);
  const formConfig: Record<number, FieldConfig<AssetPostForm>[]> = {
    0: [
      {
        fieldName: "type",
        fieldType: FIELD_TYPES.SELECT,
        label: "type",
        options: typeOption.map((opt) => ({ value: opt, label: opt })),
      },
      {
        fieldName: "comment.value",
        label: "comment",
        fieldType: FIELD_TYPES.INPUT_TEXT,
      },
      {
        fieldName: "nb_of_shades_to_shoot.value",
        label: "nb_of_shades_to_shoot",
        fieldType: FIELD_TYPES.INPUT_NUMBER,
      },
      {
        fieldName: "nb_of_carnations.value",
        label: "nb_of_carnations",
        fieldType: FIELD_TYPES.INPUT_NUMBER,
      },
      {
        fieldName: "bench_visuals",
        label: "bench_visuals",
        fieldType: FIELD_TYPES.CAROUSEL,
        images: formValues.bench_visuals,
        accept: ACCEPT_FILES_TYPES.BENCH_VISUALS,
        title: "or Drag bench visuals",
      },
      {
        fieldName: "skus.value",
        label: "skus",
        fieldType: FIELD_TYPES.INPUT_NUMBER,
      },
      {
        fieldName: "shades.value",
        label: "shades",
        fieldType: FIELD_TYPES.INPUT_NUMBER,
      },
    ],
    1: [
      {
        fieldName: "geographic_scope",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.geographic_scope,
        mandatory: true,
        label: "geographic_scope",
      },
      {
        fieldName: "ppage",
        label: "ppage",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.ppage,
      },
      {
        fieldName: "digital_animation",
        label: "digital_animation",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.digital_animation,
      },
      {
        fieldName: "media_traditional_tv_dooh_ooh",
        label: "media_traditional_tv_dooh_ooh",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.media_traditional_tv_dooh_ooh,
      },
      {
        fieldName: "media_digital",
        label: "media_digital",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.media_digital,
      },
      {
        fieldName: "permanent_instore",
        label: "permanent_instore",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.permanent_instore,
      },
      {
        fieldName: "temporary_instore",
        label: "temporary_instore",
        fieldType: FIELD_TYPES.MULTI_SELECT,
        options: possibleValues.temporary_instore,
      },
    ],
  };

  const handleClickPrev = async () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const handleClickNext = async () => {
    await trigger(formConfig[currentStep].map((field) => field.fieldName));
    if (isValid) {
      setCurrentStep((prevState) => prevState + 1);
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {currentStep === 1 && (
        <Grid container>
          <Stack direction="row" spacing={1}>
            {[formValues.type, formValues.comment?.value].map((value) => (
              <Chip label={value} key={value} size="small" />
            ))}
          </Stack>
        </Grid>
      )}
      <Grid container display="flex" justifyContent="space-between">
        <Grid item display="flex" flexDirection="column" flex={1}>
          {formConfig[currentStep]?.map((field) => (
            <Grid item key={field.fieldName} my={1} sx={{ width: "100%" }}>
              <GenericFormField
                field={field}
                setValue={setValue}
                register={register}
                control={control}
                watch={watch}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container my={2}>
        {currentStep === 0 ? (
          <Grid item display="flex" justifyContent="flex-end" width="100%">
            <Button onClick={handleClickNext}>{t(CarrouselText.Next)}</Button>
          </Grid>
        ) : (
          <Grid item display="flex" justifyContent="space-between" width="100%">
            <Button onClick={handleClickPrev} variant="outlined">
              {t(CarrouselText.Back)}
            </Button>

            <Button disabled={isLoading} type="submit">
              {t(ProductText.AddAsset)}
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default AddAssetModal;
