import React, { useEffect, useState } from "react";

import {
  StyledImage,
  StyledImageContainer,
  StyledImagePlaceholder,
} from "@components/Image/Image.styled";
import type { AssetContentFieldStatus } from "@src/types";

interface ImageComponentProps {
  url?: string;
  isImgCarousel?: boolean;
  status?: AssetContentFieldStatus;
}

const ImageComponent: React.FC<ImageComponentProps> = ({
  url,
  isImgCarousel,
  status,
}) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    if (url) {
      img.onload = () => {
        setImageUrl(url);
      };
      img.onerror = () => {
        setImageUrl("/placeholder.jpg");
      };
      img.src = url;
    }
  }, [url]);

  return (
    <StyledImageContainer status={status} isImgCarousel={isImgCarousel}>
      {imageUrl && imageUrl !== "" ? (
        <StyledImage src={imageUrl} alt="Image" />
      ) : (
        <StyledImagePlaceholder />
      )}
    </StyledImageContainer>
  );
};

export default ImageComponent;
