import React, { useState } from "react";

import MenuItem from "@components/NavigationBar/components/MenuItem";
import { Collapse, List } from "@mui/material";
import { useLazyGetCharterQuery } from "@store/api/file";

const ChartersTitle = "Charters";

const charters = [
  { label: "Charter 1", fileName: "charter-1.pdf" },
  { label: "Charter 2", fileName: "charter-2.pdf" },
  { label: "Charter 3", fileName: "charter-3.pdf" },
  { label: "Charter 4", fileName: "charter-4.pdf" },
  { label: "Charter 5", fileName: "charter-5.pdf" },
  { label: "Charter 6", fileName: "charter-6.pdf" },
  { label: "Charter 7", fileName: "charter-7.pdf" },
  { label: "Charter 8", fileName: "charter-8.pdf" },
];

function NavCatCharters() {
  const [getCharter] = useLazyGetCharterQuery();
  const [showCharters, setShowCharters] = useState(false);

  const handleCharterClick = () => {
    setShowCharters(!showCharters);
  };

  return (
    <>
      <MenuItem
        label={ChartersTitle}
        onClick={() => {
          handleCharterClick();
        }}
        hasNestedRoutes
        isNestedMenuOpen={showCharters}
      />
      <Collapse in={showCharters} timeout="auto" unmountOnExit>
        <List sx={{ ml: 2 }}>
          {charters.map((charter) => (
            <MenuItem
              key={charter.label}
              label={charter.label}
              onClick={async () => {
                const response = await getCharter({
                  fileName: charter.fileName,
                });
                window.open(response.data?.url, "_blank");
              }}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default NavCatCharters;
